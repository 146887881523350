<template>
  <div class='system-configs-page'>
    <div class='actions d-flex justify-end align-center mb-20'>
      <a-button @click="onFetchData" class="mr-15">
        <a-icon type='sync' />
        Refresh
      </a-button>
    </div>
    <system-configs-table ref="systemConfigsTable" />
  </div>
</template>

<script>
export default {
  name: 'SystemConfigs',
  components: {
    SystemConfigsTable: () => import('@/components/Pages/SystemConfigs/SystemConfigsTable')
  },
  data() {
    return {
      breadcrumb: [
        {
          path: '/system-config',
          label: 'System configs'
        }
      ]
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
  },

  methods: {
    onFetchData() {
      this.$refs.systemConfigsTable.fetchData()
    }
  }
}
</script>

<style lang='scss' scoped>
.system-configs-page {
  padding: 24px;
}
</style>
